import React from 'react';
import {PortfolioCard} from "../PortifolioCard/PortfolioCard";
import DelfosimMockup from "../../assets/Mockup-DELFOSIMAPP.png";
import PowerhubMockup from "../../assets/Mockup-POWERHUB.png";
import Way2APPMockup from "../../assets/Mockup-WAY2APP.png";
import {useStyles} from "./SolutionsScreenStyle";
import Colors from "../../assets/colors/Colors";
import useWindowWidth from '../../utils/getWidth';
import { useMediaQuery } from '@material-ui/core';
import { desktopMediaQuery } from '../../utils/globalParameters';

const useCardHeight = () => {
    const width = useWindowWidth();
    const responsiveHeightFromWidth = width * 0.46;
    const isDesktop = useMediaQuery(desktopMediaQuery);
    return isDesktop ? {height: responsiveHeightFromWidth} : {};
}

function SolutionsSection() {
    const delfosDescription = (color: string) => {
        return (
            <span style={{color: color}}>
                A renewable energy management&nbsp;
                <b>modular solution</b> for monitoring and managing&nbsp;
                <b>data in real time</b>, getting analysis and performance&nbsp;
                <b>indicators</b>, generating and sharing&nbsp;
                <b>reports</b>, receiving health status and failure prediction&nbsp;
                <b>alerts</b>.
             </span>
        )
    }
    const powerHubDescription = (color: string) => {
        return (
            <span style={{color: color}}>
                A remote energy consumption management solution that gives&nbsp;
                <b>data overview</b> in clear and focused view format,&nbsp;
                <b>historical charts</b>, alarms and events&nbsp;
                <b>monitoring panels</b>, user&nbsp;
                <b>theme</b> customization.
            </span>
        )
    }
    const way2Description = (color: string) => {
        return (
            <span style={{color: color}}>
                An enlightening energy generation monitoring solution that provides&nbsp;
                <b>insightful dashboards</b>, historical&nbsp;
                <b>analysis</b>, production&nbsp;
                <b>goals tracking</b>,&nbsp;
                <b>data comparison</b> and&nbsp;
                <b>modular visualization</b>.
             </span>
        )
    }
    const classes = useStyles();
    const cardHeightStyle = useCardHeight();
    return (
        <div className="Solutions" id="Solutions">
            <section id={'DelfosIM'} className={classes.section} style={cardHeightStyle}>
                <PortfolioCard
                    header={{title: "DelfosIM", position: "left", bgColor: "darkGray"}}
                    backgroundColor={Colors.lightBlue}
                    description={{
                        element: delfosDescription(Colors.white),
                        actionText: 'See more apps',
                        actionDestination: 'PowerHub',
                        actionTextColor: Colors.white,
                        descriptionPosition: 'left'
                    }}
                    imgUrl={DelfosimMockup.toString()}
                />
            </section>
            <section id={'PowerHub'} className={classes.section} style={cardHeightStyle}>
                <PortfolioCard
                    header={{title: "PowerHub", position: "right", bgColor: "blue"}}
                    backgroundColor={Colors.skyBlue}
                    description={{
                        element: powerHubDescription(Colors.white),
                        actionText: 'See more apps',
                        actionDestination: 'Way2App',
                        actionTextColor: Colors.white,
                        descriptionPosition: 'right',
                        clientLink: 'https://www.way2.com.br/'
                    }}
                    imgUrl={PowerhubMockup.toString()}
                />
            </section>
            <section id={'Way2App'} className={classes.section} style={cardHeightStyle}>
                <PortfolioCard
                    header={{title: "Way2", position: "left", bgColor: "lightBlue"}}
                    backgroundColor={Colors.smoke}
                    description={{
                        element: way2Description('black'),
                        actionText: 'See how we work',
                        actionDestination: 'Techs',
                        actionTextColor: 'black',
                        descriptionPosition: 'left',
                        clientLink: 'https://www.way2.com.br/'
                    }}
                    imgUrl={Way2APPMockup.toString()}
                />
            </section>
        </div>
    );
}

export default SolutionsSection;
