import React from 'react';
import './SectionHeader.css';

export interface SectionHeaderProps {
	title: string,
	position: "left" | "center" | "right",
	bgColor: "skyBlue" | "lightBlue" | "blue" | "darkGray",
}

function SectionHeader(props: SectionHeaderProps) {
	const mainClassName = {
		sectionContainer: `header-box ${props.position} bg-color-${props.bgColor}`,
		titleClassName: `header-box-text ${props.position === 'center' ? 'who-we-are-center' : ''}`,
	}
	return (
        <div className={mainClassName.sectionContainer}>
            <span className={mainClassName.titleClassName}>{props.title}</span>
        </div>
	);
}

export default SectionHeader;
