import React, {useState, useEffect, useCallback} from 'react';
import {Link, animateScroll as scroll} from 'react-scroll';
import ResponsiveDrawer from './responsiveDrawer';
import './Header.css';
import {Logo} from "../Svgs/Logo";
import {Fade} from "@material-ui/core";
import useWindowWidth from "../../utils/getWidth";
// TODO set up cool animations on header

function Header() {
    const [prevScrollPosition, setScrollPosition] = useState(0);
    const [showAppBar, setAppBarVisibility] = useState(true);
    const [clickedInHeaderBtn, setClickedInHeaderBtn] = useState(false);
    let timeoutRef: NodeJS.Timeout | null = null;
    const contactButtonClassName = showAppBar ? 'contact-container' : 'contact-container-animated';
  
    const onHeaderLinkClick = () => {
      setClickedInHeaderBtn(true);
      if(timeoutRef != null) {
        clearTimeout(timeoutRef);
        timeoutRef = null;
      }
      timeoutRef = setTimeout(() => {
        setClickedInHeaderBtn(false);
        timeoutRef = null;
      }, 800);
    }

  const renderMenuItems = () => {
    return(
      <div className={"navbar-buttons-container"}>
        <Link 
          to="Solutions"
          smooth
          offset={-45}
          duration={500}
          onClick={onHeaderLinkClick}
        >
          <span className="navbar-button"> Solutions </span>
        </Link>
        <Link 
          to="Techs"
          smooth
          offset={-85}
          duration={500}
          onClick={onHeaderLinkClick}
        >
          <span className="navbar-button"> Techs </span>
        </Link>
        <Link 
          to="Talents"
          smooth
          offset={-100}
          duration={500}
          onClick={onHeaderLinkClick}
        >
          <span className="navbar-button"> Talents </span>
        </Link>
    </div>
    );
  }

  const renderMenu= (width: number) => {
    if(width > 915) {
      return (
        renderMenuItems()
      );
    }
    else {
      return (
        <div className="mobile-navbar-buttons-container">
          <ResponsiveDrawer/>
        </div>
      );
    }
  }

    const listenScrollEvent = useCallback((e: Event) => {
        setScrollPosition(window.scrollY);
        if (prevScrollPosition < window.scrollY && !clickedInHeaderBtn) {
            //Hide AppBar immediate
            setAppBarVisibility(false);
            return;
        } else if (prevScrollPosition > window.scrollY) {
            //Reveal AppBar immediate
            setAppBarVisibility(true);
            return;
        }
    },[prevScrollPosition, clickedInHeaderBtn])

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
        return () => {
            window.removeEventListener('scroll', listenScrollEvent);
        }
    }, [listenScrollEvent, prevScrollPosition, setAppBarVisibility]);

    const getContactBtnRightDistance = (width: number):number => {
      if(width > 915) {
        return showAppBar ? 44 : 20;
      }
      return showAppBar ? 84 : 20;
    }

  return (
    <div>  
      <Fade in={showAppBar} timeout={{enter: 250, appear: 500, exit: 250}}>
          <div id="navbar" className="nav-bar-container">
              <button className="logo-link-container" onClick={() => {scroll.scrollToTop()}}>
                  <div className="header-logo">
                      <Logo type={useWindowWidth() > 440 ? 'header' : 'mobileHeader'}/>
                  </div>
              </button>
              {renderMenu(useWindowWidth())}
          </div>
      </Fade>
      <div className="floating-contact-btn-container" style={{right: getContactBtnRightDistance(useWindowWidth())}}>
        <Link 
          to="Contact"
          smooth
          offset={-95}
          duration={500}
          onClick={onHeaderLinkClick}
        >
          <div className={contactButtonClassName}>
            <span className="navbar-button"> Contact </span>
          </div>
        </Link>
      </div>
    </div>
  );
}
export default Header;
