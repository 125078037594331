import React from 'react';
import {
    CssBaseline, 
    Drawer, 
    IconButton, 
    List, 
    ListItem,
    ListItemText,
    Toolbar,  
    makeStyles, 
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-scroll';

const drawerWidth = 200;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
}));

function ResponsiveDrawer() {
  const categories: {name:string, offset:number}[] = [{name: 'Solutions', offset: -45}, {name: 'Techs', offset: -85}, {name: 'Talents', offset: -100}, {name: 'Contact', offset: -95}];
  
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
    
  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen)
  }
    
  const drawer = (
    <List>
      {categories.map((item, index) => (
        <Link
          to={item.name.replace(' ', '')}
          smooth
          offset={item.offset}
          duration={500}
          key={item.name}
        >
          <ListItem button onClick={() => {
            handleDrawerToggle();
          }}>
            <ListItemText>
              {item.name}
            </ListItemText>
          </ListItem>
        </Link>
      ))}
    </List>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon/>
        </IconButton>
      </Toolbar>
        
      <nav className={classes.drawer}>
        <Drawer
          variant="temporary"
          anchor={'right'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
            <CloseIcon/>
          </IconButton>
          {drawer}
        </Drawer>
      </nav>
    </div>
  );
}
export default ResponsiveDrawer;