import React from "react";
import SectionHeader, { SectionHeaderProps } from "../SectionHeader/SectionHeader";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ArrowDownwardSharp from "@material-ui/icons/ArrowDownwardSharp";
import 'typeface-roboto';
import { Link } from 'react-scroll';
import Paper from "@material-ui/core/Paper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { styles } from "./PortfolioCardStyle";
import { mobileMediaQuery } from "../../utils/globalParameters";

interface PortfolioCardProps {
    header: SectionHeaderProps,
    backgroundColor: string,
    description: {
        element: any,
        actionText: string,
        actionTextColor: string,
        actionDestination: string,
        descriptionPosition: 'left' | 'right',
        clientLink?: string,
    },
    imgUrl: string
}

export function PortfolioCard(props: PortfolioCardProps) {
    const isMobile = useMediaQuery(mobileMediaQuery);
    const useStyles = makeStyles(
        styles(props.backgroundColor, props.description.actionTextColor, isMobile)
    );
    const classes = useStyles();
    const flexDirection = props.description.descriptionPosition === 'left' ? 'row-reverse' : 'row';
    const justifyOfDescriptionActionText = props.description.descriptionPosition === 'left' ? 'flex-start' : 'flex-end';
    const alignOfDescription = props.description.descriptionPosition;

    const renderClientLink = () => {
        if(props.description.clientLink) {
            return (
                <a
                    href={props.description.clientLink}
                    target={'_blank'}
                    rel={"noopener noreferrer"}
                    className={classes.clientLink}
                >
                    <Typography className={classes.descriptionContent}>
                        Visit Website
                    </Typography>
                </a>
            );
        }
    }

    const renderDesktopContent = () => {
        return (
            <Grid container direction={flexDirection}>
                <Grid item xs={6} xl={6} md={6} sm={6}>
                    <img src={props.imgUrl} alt={props.header.title} className={classes.smartphoneImage} />
                </Grid>
                <Grid item xs={6} xl={6} md={6} sm={6} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    <div className={classes.description} style={props.description.descriptionPosition === 'left' ? {paddingRight: 24} : {paddingLeft: 24}}>
                        <Typography align={alignOfDescription} className={classes.descriptionContent}>
                            {props.description.element}
                            { renderClientLink() }
                        </Typography>
                    </div>
                    <div className={classes.actionContainer} style={{display: 'flex', justifyContent: justifyOfDescriptionActionText}}>
                        <Link
                            to={props.description.actionDestination}
                            smooth
                            duration={500}
                            className={classes.actionTextAndIconContainer}
                        >
                            <Typography className={classes.actionText}>
                                {props.description.actionText}
                            </Typography>
                            <ArrowDownwardSharp className={classes.actionIcon} />
                        </Link>
                    </div>
                </Grid>
            </Grid>
        )
    }
    const renderMobileContent = () => {
        return (
            <Grid container direction={flexDirection} alignItems={'center'}
                justify={'center'}>
                <Grid item xs={12} xl={12} md={12} sm={12}>
                    <Carousel showThumbs={false} showStatus={false} className={classes.carousel}>
                        <div className={classes.carousel}>
                            <img src={props.imgUrl} alt={props.header.title} width={'100vh'} className={classes.smartphoneImage} />
                        </div>
                        <div className={classes.carousel} style={{flexDirection: 'column'}}>
                            <Typography align={'center'} className={classes.descriptionContent}>
                                {props.description.element}
                            </Typography>
                            <Link
                                to={props.description.actionDestination}
                                smooth
                                offset={-156}
                                duration={500}
                                className={classes.actionTextAndIconContainer}
                            >
                                <Typography className={classes.actionText}>
                                    {props.description.actionText}
                                </Typography>
                                <ArrowDownwardSharp className={classes.actionIcon} />
                            </Link>
                        </div>
                    </Carousel>
                </Grid>
            </Grid>
        )
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <SectionHeader title={props.header.title} position={isMobile ? "center" : props.header.position} bgColor={props.header.bgColor} />
                <div className={classes.contentContainer}>
                    {isMobile ? renderMobileContent() : renderDesktopContent()}
                </div>
            </Paper>
        </div>
    )
}

