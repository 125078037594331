export const styles = (
    backgroundColor: string,
    actionTextColor: string,
    isMobile: boolean,
) => {
    return {
        break: {
            height: 55,
        },
        contentContainer: {
            padding: isMobile ? '36px 24px 16px 24px' : '36px 46px',
            background: backgroundColor,
        },
        root: {
            margin: '2% 4%',
            transition: `
                margin 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
            `,
            '&:hover': {
                margin: '1.5% 3.5%',
                transition: `
                    margin 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
                `,
            },
        },
        carousel: {
            background: backgroundColor,
            display: 'flex',
            justifyContent: 'space-between',
        },
        paper: {
            boxShadow: '0 2px 2px 2px rgba(0,0,0, 0.3)',
            transition: 'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            '&:hover': {
                boxShadow: '0 5px 5px 5px rgba(0,0,0, 0.3)',
                transition: 'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            },
        },
        smartphoneImage: {
            maxWidth: '100%',
        },
        description: {
            width: '100%',
            marginTop: 32,
        },
        descriptionContent: {
            fontFamily: 'roboto',
            fontStyle: 'normal',
            lineHeight: 1.5,
            fontSize: '2rem',
            margin: 'auto',
            '@media (max-width: 605px)': {
                fontSize: '1.55rem',
            },
            '@media (max-width: 480px)': {
                fontSize: '1.2rem',
            }
        },
        actionContainer: {
            width: '100%',
        },
        actionTextAndIconContainer: {
            display: 'flex',
            alignItems: 'center',

            color: actionTextColor,
            cursor: 'pointer',

            '@media (max-width: 769px)': {
                justifyContent: 'center',
            }
        },
        actionText: {
            fontFamily: 'roboto',
            fontSize: '1.5rem',
            transition: 'font-size 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            cursor: 'pointer',
            color: actionTextColor,
            '&:hover': {
                fontSize: '1.7rem',
                transition: 'font-size 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            },
            '@media (min-width: 1350px)': {
                fontSize: '1.7rem',
                '&:hover': {
                    fontSize: '1.9rem',
                    transition: 'font-size 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                }
            }
        },
        actionIcon: {
            marginTop: 4,
            marginLeft: 6,
        },
        clientLink: {
            textDecoration: 'none',
            color: actionTextColor,
            '&:hover': {
                textDecoration: 'underline'
            }
        }
    }

}