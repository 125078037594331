import React from 'react';
import {Link} from 'react-scroll';
import moment from 'moment';
import './Footer.css';
import {Logo} from "../Svgs/Logo";


function Footer() {
  return (
    <div className="Footer">
      <div className="footer-container">
        <div className="menu-container">
          <Link 
            to="AboutUs"
            smooth
            offset={-100}
            duration={500}
          >
            <span className="menu-button"> How we describe ourselves </span>
          </Link>
          <Link 
            to="Solutions"
            smooth
            offset={-45}
            duration={500}
          >
            <span className="menu-button"> What we've been doing recently </span>
          </Link>
          <Link 
            to="Techs"
            smooth
            offset={-85}
            duration={500}
          >
            <span className="menu-button"> What (or who) we work with </span>
          </Link>
          <Link 
            to="Talents"
            smooth
            offset={-100}
            duration={500}
          >
            <span className="menu-button"> Who we are </span>
          </Link>
          <Link 
            to="Contact"
            smooth
            offset={-95}
            duration={500}
          >
            <span className="menu-button"> How to talk to us </span>
          </Link>
        </div>
        <div className="logo-info">
          <div className="logo-container">
              <Logo type={'footer'} />
          </div>
          <div>
            <span className="logo-info-text">{`${moment().format('YYYY')}, São José dos Campos - SP, Brazil`}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
