import React, { useState, useEffect } from 'react';
import './IntroSection.css';
import { Link } from 'react-scroll';
import specialWordsList from '../../content/specialWordsList';
import {Fade} from "@material-ui/core";
import {ArrowDownwardSharp} from "@material-ui/icons";

function IntroSection() {
  const [specialWord, setSpecialWord] = useState(specialWordsList[0]);
  const [textDisplay, setTextDisplay] = useState(false);
  
  useEffect(() => {
    let listIndex = -1;
    const changeWords = () => {
      listIndex === specialWordsList.length - 1 ? listIndex = 0 : listIndex += 1;
      setSpecialWord(specialWordsList[listIndex]);
      setTimeout(changeWords, 4000);
      setTimeout(() => setTextDisplay(true), 500);
      setTimeout(() => setTextDisplay(false), 3000);
    }
    changeWords();
  }, []);

  return (
    <div id="AboutUs" className="LogoSection">
      <div className="background">
        <div className="main-text-container">
          <h1 className="main-text">
            We develop
            <div className="special-word-container">
              <Fade in={textDisplay} timeout={{appear: 1000, enter: 1000, exit: 1000}}>
                <span className="special-word">{` ${specialWord} `}</span>
              </Fade>
            </div>
            solutions
          </h1>
        </div>
        <div className="link-container">
          <Link 
            to="Solutions"
            smooth
            offset={-25}
            duration={500}
          >
            <span className="show-more-text">Let us show you</span>
            <ArrowDownwardSharp className="show-more-icon"/>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default IntroSection;
