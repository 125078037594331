import firebase from 'firebase/app';
import "firebase/firebase-analytics";

const cernovApps = firebase.initializeApp(
    {
        apiKey: "AIzaSyCmgsP5BqbWXbfvkph56DNzvphstafwDrM",
        authDomain: "cernov-apps.firebaseapp.com",
        databaseURL: "https://cernov-apps.firebaseio.com",
        projectId: "cernov-apps",
        storageBucket: "cernov-apps.appspot.com",
        messagingSenderId: "1071515914480",
        appId: "1:1071515914480:web:667e447fd1324230797235",
        measurementId: "G-ETSHHEW5JK"
    }
);
export const defaultAnalytics = firebase.analytics(cernovApps);