import CernovAppsLogo from '../../assets/cernov-logo.svg'
import SmallLogo from '../../assets/small-logo.svg';
import React from "react";
import './Logo.css';

type sectionTypes = 'body' | 'footer' | 'header' | 'headerSmall' | 'mobileHeader';

export const Logo: ({type}: {type: sectionTypes}) => any = ({type}) => {
    const matchSize: {
        [type: string]: {height: number, width: number | string}
    } = {
        'header': {height: 62, width: 206},
        'mobileHeader': {height: 36, width: "auto"},
        'body': {height: 291, width: 971},
        'footer': {height: 156, width: 520},
        'headerSmall': {height: 43, width: "auto" }
    }
    
    return (
        <img
            className="logo-image"
            alt={'Logo cernov apps'}
            height={matchSize[type].height}
            width={matchSize[type].width}
            src={type === 'headerSmall' ? SmallLogo : CernovAppsLogo}/>
    )
}