import {createStyles, makeStyles, Theme} from "@material-ui/core";
import Colors from "../../assets/colors/Colors";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        userDataField: {
          width: '100%'
        },
        textInput: {
            margin: theme.spacing(2),
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: Colors.lightGray,
                color: Colors.darkGray,
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: Colors.blue,
            }
        },
        text: {
            color: Colors.darkGray,
            zIndex: 0,
        },
        inputSplit: {
            color: Colors.lightGray,
            maxWidth: "100%",
        },
        submitBtnContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            width: "100%",
            marginTop: 10,
            marginRight: 15,
        },
        submitBtn: {
            borderColor: Colors.lightGray,
        }
    }),
);

export const contactUsTheme = createMuiTheme({
    overrides: {
        MuiFormLabel: {
            asterisk: {
                display: 'none',
            }
        }
    }
})