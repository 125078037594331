import React, {useState, useRef} from 'react';
import {Button, TextField, ThemeProvider, Snackbar, useMediaQuery} from '@material-ui/core';
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';
import {Send, LinkedIn, Phone, Email} from '@material-ui/icons';
import './ContactSection.css';
import {contactUsTheme, useStyles} from "./ContactSectionStyles";
import Box from "@material-ui/core/Box";
import useWindowWidth from "../../utils/getWidth";
import { mobileWidth, desktopMediaQuery } from '../../utils/globalParameters';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ContactSection() {
  const classes = useStyles();
  const [copySuccess, setCopySuccess] = useState({success: false, id: ''});
  const emailInputRef = useRef<HTMLInputElement>(null);
  const phoneInputRef = useRef<HTMLInputElement>(null);

  const isDesktop = useMediaQuery(desktopMediaQuery);
  const screenWidth: number = useWindowWidth();

  const copyToClipboard = (inputId: string) => {
    let copyText = document.getElementById(inputId) as HTMLInputElement;
    copyText!.select();
    document.execCommand('copy');
    setCopySuccess({success: true, id: inputId});
  }

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setCopySuccess({success: false, id: ''});
  };

  const renderTextMessage = ():string | undefined => {
    switch(copySuccess.id) {
      case "email":
        return 'Our email was copied to your clipboard!';
      case "phoneNumber":
        return 'Our phone number was copied to your clipboard!';
    }
  }

  const onPhoneClick = (width: number) => {
    width > mobileWidth ? copyToClipboard("phoneNumber") : window.open("tel:+55 12 98173-7533", "_blank");
  }

  return (
    <div id="Contact" className="contact-section-container">
      <h1 className="section-title contact-section">Just say the word</h1>
      <ThemeProvider theme={contactUsTheme} >
        <form className="contact-form" action={"https://mailthis.to/cernovapps"} method={'POST'} target={'_blank'}>
          <Box display={'flex'} flexDirection={ isDesktop ? 'row' : 'column' } className={classes.userDataField} >
              <TextField
                  className={classes.textInput}
                  InputProps={{
                      className: classes.inputSplit
                  }}
                  InputLabelProps={{
                      className: classes.text,
                  }}
                  required
                  name="_replyto"
                  type="email"
                  label="Email"
                  variant="outlined"
                  fullWidth={isDesktop}
              />
              <TextField
                  className={classes.textInput}
                  InputProps={{
                      className: classes.inputSplit
                  }}
                  InputLabelProps={{
                      className: classes.text
                  }}
                  required
                  name="name"
                  type="text"
                  label="Name"
                  variant="outlined"
                  fullWidth={isDesktop}
              />
          </Box>
          <TextField
              className={classes.textInput}
              InputProps={{
                className: classes.text
              }}
              InputLabelProps={{
                className: classes.text
              }}
              required
              fullWidth
              name={"subject"}
              type="text"
              label="Subject"
              variant="outlined"
          />
          <TextField
              className={classes.textInput}
              InputProps={{
                className: classes.text
              }}
              InputLabelProps={{
                className: classes.text
              }}
              required
              fullWidth
              multiline
              rows="10"
              name={"message"}
              type="text"
              label="Message"
              variant="outlined"
          />
          <div className={classes.submitBtnContainer}>
            <Button
              className={classes.submitBtn}
              type="submit"
              variant="outlined"
              size="large"
              endIcon={<Send/>}
            >
              <span className="buttonText"> Send </span>
            </Button>
          </div>
        </form>
      </ThemeProvider>
      <h2 className="section-title call-us-section">Or call us on</h2>
      <div className="info-container">
        <button
          className="info-box"
          onClick={() => copyToClipboard("email")}
          onMouseEnter={() => {emailInputRef.current!.style.width = emailInputRef.current!.value.length + 1.4 + 'ch'}}
          onMouseLeave={() => {emailInputRef.current!.style.width = emailInputRef.current!.value.length + 'ch'}}
        >
          <Email className="info-box-icon"/>
          <input
            id="email"
            value="contact@cernovapps.com"
            readOnly
            ref={emailInputRef}
            style={{width: emailInputRef.current ? emailInputRef.current.value.length + 'ch' : 100}}
          />
        </button>
        <button className="info-box" onClick={() => onPhoneClick(screenWidth)}>
          <Phone className="info-box-icon"/>
          <input
            id="phoneNumber"
            value="+55 12 98173-7533"
            readOnly
            ref={phoneInputRef}
            style={{width: phoneInputRef.current ? phoneInputRef.current.value.length + 'ch' : 100}}
          />
        </button>
        <button
          className="info-box"
          onClick={() => window.open("https://linkedin.com/company/cernov-apps", "_blank")}
        >
          <LinkedIn className="info-box-icon"/>
          <span id="linkedin"> linkedin.com/company/cernov-apps </span>
        </button>
      </div>
      <Snackbar open={copySuccess.success} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {renderTextMessage()}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ContactSection;
