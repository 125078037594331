import React from 'react';
import './WeWorkWithSection.css';
import Colors from '../../assets/colors/Colors';

function renderSquare(feature: string, color: string) {
    return (
        <div className="square" style={{ backgroundColor: color }}>
            <span className="square-text">{feature}</span>
        </div>
    );
}

function WeWorkWithSection() {
  return (
    <div className="WeWorkWithSection" id="Techs">
        <h1 className="section-title techs-section">We work with</h1>
        <div className="container">
            {renderSquare('App design', Colors.skyBlue)}
            {renderSquare('Native and hybrid app development', Colors.lightBlue)}
            {renderSquare('Web development', Colors.blue)}
            {renderSquare('Hardware integration', Colors.darkGray)}
            {renderSquare('Data analysis', Colors.darkGray)}
            {renderSquare('Agile development', Colors.blue)}
            {renderSquare('Durable solutions', Colors.lightBlue)}
            {renderSquare('Scrum method', Colors.skyBlue)}
        </div>
    </div>
  );
}

export default WeWorkWithSection;
