const Colors = {
    skyBlue: '#1E97E6',
    lightBlue: '#0070B9',
    blue: '#003D89',
    darkGray: '#3B3B3B',
    lightGray: '#707070',
    smoke: '#E0E7EB',
    white: '#F2F2F2',
}

export default Colors;