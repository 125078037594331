import React from 'react';
import './LandingPage.css';
import Header from '../../components/Header/Header';
import IntroSection from '../../components/IntroSection/IntroSection';
import SolutionsSection from '../../components/SolutionsSection/SolutionsSection';
import ContactSection from '../../components/ContactSection/ContactSection';
import WeWorkWithSection from '../../components/WeWorkWithSection/WeWorkWithSection';
import Footer from '../../components/Footer/Footer';
import { defaultAnalytics } from '../../utils/firebaseConfigs';

function LandingPage() {
  defaultAnalytics.logEvent('loaded_landing_page');
  return (
    <div className="LandingPage">
        <Header/>
        <IntroSection/>
        <SolutionsSection/>
        <WeWorkWithSection/>
        <ContactSection/>
        <Footer/>
    </div>
  );
}

export default LandingPage;
