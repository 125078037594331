import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles({
    section: {
        display: 'flex',
        marginBottom: 32,
        '@media (max-width: 769px)': {
            marginBottom: 64,
        }
    }
});
